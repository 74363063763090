<template>
  <div v-if="localContainer">
    <div class="row mt-3">
      <div class="col-md-2 text-center">
        <div>
          <img :src="localContainer.image" alt="Container Image" class="w-75" />
          <h5>{{ localContainer.name }}</h5>
        </div>
      </div>
      <div class="col-md-10">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="itemsMax" class="col-sm-4 col-form-label">ItemsMax</label>
            <div class="col-sm-8">
              <input type="number" class="form-control form-control-sm" id="itemsMax" v-model.number="localContainer.itemsMax" @input="updateContainer" />
            </div>
          </div>
          <div class="col-md-4">
            <label for="itemsMin" class="col-sm-4 col-form-label">ItemsMin</label>
            <div class="col-sm-8">
              <input type="number" class="form-control form-control-sm" id="itemsMin" v-model.number="localContainer.itemsMin" @input="updateContainer" />
            </div>
          </div>
          <div class="col-md-2">
            <label for="enabled" class="col-sm-4 col-form-label">Enabled</label>
            <div class="col-sm-8">
              <input type="checkbox" class="form-check-input" id="enabled" v-model="localContainer.enabled" @input="updateContainer" />
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="maxBPs" class="col-sm-4 col-form-label">MaxBPs</label>
            <div class="col-sm-8">
              <input type="number" class="form-control form-control-sm" id="maxBPs" v-model.number="localContainer.maxBPs" @input="updateContainer" />
            </div>
          </div>
          <div class="col-md-4">
            <label for="scrap" class="col-sm-4 col-form-label">Scrap</label>
            <div class="col-sm-8">
              <input type="number" class="form-control form-control-sm" id="scrap" v-model.number="localContainer.scrap" @input="updateContainer" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Displaying container items -->
    <div class="row row-cols-1 row-cols-md-4 g-4 mt-2">
      <div v-for="(item, index) in containerItems" :key="item.shortname + index" class="col">
        <div class="card bg-dark p-2">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div>
              <img :src="item.image" alt="Item Image" />
              <span class="text-white ms-2">{{ item.name }}</span>
            </div>
            <div class="d-flex gap-2">
              <div v-if="item.hasBlueprint && !item.isBlueprint" class="form-check">
                <input :id="'btncheck-' + item.shortname" class="btn-check btn-check-sm" type="checkbox" autocomplete="off" @change="toggleBlueprint(item, $event)" :checked="isBlueprintAdded(item)" />
                <label class="btn btn-sm btn-outline-primary" :for="'btncheck-' + item.shortname">BP</label>
              </div>
              <button class="btn btn-sm btn-danger" @click="removeItem(index, item)"><i class="bi bi-trash"></i></button>
            </div>
          </div>
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text">Min</span>
            <input type="number" class="form-control form-control-sm" v-model.number="item.min" @input="updateItem(index)" />
          </div>
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text">Max</span>
            <input type="number" class="form-control form-control-sm" v-model.number="item.max" @input="updateItem(index)" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Please select a container.</p>
  </div>
</template>

<script>
export default {
  props: ["selectedContainer", "containerItems", "containers"],
  data() {
    return {
      localContainer: this.selectedContainer ? { ...this.selectedContainer } : null,
    };
  },
  watch: {
    selectedContainer: {
      handler(newContainer) {
        this.localContainer = newContainer ? { ...newContainer } : null;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    removeItem(index, item) {
      this.$emit("remove-item", index);

      // If the removed item is a blueprint, emit an event to uncheck the checkbox
      if (item.isBlueprint) {
        this.$emit("uncheck-blueprint", item);
      }
    },
    uploadJson(event) {
      this.$emit("upload-json", event);
    },
    downloadJson() {
      this.$emit("download-json");
    },
    updateContainer() {
      this.$emit("update-container", this.localContainer);
    },
    updateItem(index) {
      this.$emit("update-item", index, this.containerItems[index]);
    },
    toggleBlueprint(item, event) {
      const isChecked = event.target.checked;
      if (isChecked) {
        const blueprintItem = { ...item, isBlueprint: true, name: item.name.replace(" BP", "") + " BP" };
        this.$emit("add-item", blueprintItem);
      } else {
        const index = this.containerItems.findIndex((ci) => ci.shortname === item.shortname && ci.isBlueprint && ci.containerId === this.selectedContainer.id);
        if (index !== -1) {
          this.$emit("remove-item", index);
        }
      }
    },
    isBlueprintAdded(item) {
      return this.containerItems.some((ci) => ci.shortname === item.shortname && ci.isBlueprint && ci.containerId === this.selectedContainer.id);
    },
  },
};
</script>

<style scoped>
h3 {
  color: white;
}
label {
  color: white;
}
</style>
