<template>
    <div class="container-list-container">
      <div class="container-list">
        <h3 class="text-white text-center">CONTAINERS</h3>
        <input type="text" v-model="searchQuery" placeholder="Search containers..." class="form-control mb-3">
        <div v-for="container in filteredContainers" :key="container.id" @click="switchContainer(container)" class="container-card">
          <img :src="container.image" :alt="container.name" class="container-image">
          <span>{{ container.name }}</span>
        </div>
      </div>
    </div>
  </template>

<script>
export default {
  props: ["containers"],
  data() {
    return {
      searchQuery: "",
    };
  },
  computed: {
    filteredContainers() {
      return this.containers.filter((container) => container.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
  },
  methods: {
    switchContainer(container) {
      this.$emit("switch-container", container);
    },
  },
};
</script>

<style scoped>
.container-list-container {
  height: 100vh; /* Set height to fill the viewport */
  overflow-y: auto; /* Add vertical scrollbar if needed */
}

.container-list {
  height: 100%; /* Set height to fill the parent container */
  padding-right: 15px; /* Add padding to compensate for scrollbar width */
  box-sizing: border-box; /* Include padding in height calculation */
}

.container-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  background-color: #333;
  color: white;
}
.container-card:hover {
  background-color: #444;
}
.container-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
</style>
