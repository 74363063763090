<template>
  <div id="app" class="container-fluid">
    <div class="row">
      <div class="col-md-2 vh-100 overflow-auto">
        <container-list :containers="containers" @switch-container="switchContainer"></container-list>
      </div>
      <div class="col-md-8">
        <nav class="navbar">
          <div class="container-fluid">
            <a class="navbar-brand" href="#">
              <img src="logo.png" alt="Looty.cc" width="30" height="24" /> <span class="text-white">LOOTY.CC</span>
            </a>
            <div class="gap-1 d-md-block">
              <button class="btn btn-danger text-uppercase me-2" @click="resetContainers"><i class="bi bi-arrow-counterclockwise"></i> Reset config</button>
              <button class="btn btn-success text-uppercase me-2" @click="downloadJson"><i class="bi bi-filetype-json"></i> Download config</button>
              <button class="btn btn-primary text-uppercase" @click="loadDefaultConfig"><i class="bi bi-upload"></i> Load Default Config</button>
            </div>
            <form class="d-flex" role="search">
              <input class="form-control me-2" type="file" @change="uploadJson" />
            </form>
          </div>
        </nav>
        <container-editor
          :selectedContainer="selectedContainer"
          :containerItems="filteredContainerItems"
          :items="items"
          @remove-item="removeItem"
          @update-container="updateContainer"
          @update-item="updateItem"
          @add-item="handleAddItem"
        ></container-editor>
      </div>
      <div class="col-md-2 vh-100 overflow-auto">
        <item-list :items="items" :containerItems="containerItems" :selectedContainer="selectedContainer" @add-item="handleAddItem"></item-list>
      </div>
    </div>
  </div>
</template>

<script>
import ItemList from "./components/ItemList.vue";
import ContainerEditor from "./components/ContainerEditor.vue";
import ContainerList from "./components/ContainerList.vue";
import { saveAs } from "file-saver";

export default {
  name: "App",
  components: {
    ItemList,
    ContainerEditor,
    ContainerList,
  },
  data() {
    return {
      items: [],
      containers: [],
      containerItems: [],
      selectedContainer: null,
    };
  },
  created() {
    this.loadItems();
    this.loadContainers();
    this.loadContainerItems();
    window.addEventListener("beforeunload", this.saveContainerItems);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.saveContainerItems);
    this.saveContainerItems();
  },
  computed: {
    filteredContainerItems() {
      if (this.selectedContainer) {
        return this.containerItems.filter((item) => item.containerId === this.selectedContainer.id);
      }
      return [];
    },
  },
  watch: {
    selectedContainer: {
      handler(newVal) {
        if (newVal) {
          document.title = `${newVal.name} - Looty.cc | BetterLoot`;
        } else {
          document.title = "Looty.cc | BetterLoot";
        }
      },
      immediate: true,
    },
  },
  methods: {
    loadItems() {
      fetch("/items.json")
        .then((response) => response.json())
        .then((data) => {
          this.items = data;
        });
    },
    loadContainers() {
      fetch("/containers.json")
        .then((response) => response.json())
        .then((data) => {
          this.containers = data;
          if (this.containers.length > 0) {
            this.selectedContainer = this.containers[0];
          }
        });
    },
    loadContainerItems() {
      const savedItems = localStorage.getItem("containerItems");
      if (savedItems) {
        this.containerItems = JSON.parse(savedItems);
      }
    },
    saveContainerItems() {
      localStorage.setItem("containerItems", JSON.stringify(this.containerItems));
    },
    handleAddItem(item) {
      if (!this.selectedContainer) {
        alert("Please select a container first");
        return;
      }
      const exists = this.containerItems.some((ci) => ci.shortname === item.shortname && ci.isBlueprint === item.isBlueprint && ci.containerId === this.selectedContainer.id);
      if (exists) {
        alert("Item already exists in the selected container");
        return;
      }
      this.containerItems.push({ ...item, containerId: this.selectedContainer.id });
    },
    removeItem(index) {
      this.containerItems.splice(index, 1);
    },
    uploadJson(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const jsonData = JSON.parse(e.target.result);
        this.containerItems = [];
        Object.keys(jsonData.LootTables).forEach((location) => {
          const container = this.containers.find((c) => c.location === location);
          if (container) {
            const items = jsonData.LootTables[location].ItemList;
            for (const [shortname, { Min, Max }] of Object.entries(items)) {
              const item = this.items.find((i) => i.shortname === shortname.replace(".blueprint", ""));
              if (item) {
                const isBlueprint = shortname.endsWith(".blueprint");
                this.containerItems.push({
                  ...item,
                  isBlueprint,
                  min: Min,
                  max: Max,
                  containerId: container.id,
                });
              }
            }
          }
        });
      };
      reader.readAsText(file);
    },
    switchContainer(container) {
      this.selectedContainer = container;
    },
    updateContainer(updatedContainer) {
      const index = this.containers.findIndex((c) => c.id === updatedContainer.id);
      if (index !== -1) {
        this.containers.splice(index, 1, { ...updatedContainer });
      }
    },
    updateItem(index, updatedItem) {
      this.containerItems.splice(index, 1, { ...updatedItem });
    },
    resetContainers() {
      this.containerItems = [];
    },
    downloadJson() {
      const jsonConfig = this.createJsonConfig();
      const blob = new Blob([JSON.stringify(jsonConfig, null, 2)], { type: "application/json" });
      saveAs(blob, "loot_config.json");
      this.resetContainers();
    },
    createJsonConfig() {
      const lootTables = {};
      (this.containers || []).forEach((container) => {
        lootTables[container.location] = {
          Enabled: container.enabled,
          ItemList: this.containerItems
            .filter((item) => item.containerId === container.id)
            .reduce((acc, item) => {
              const key = item.isBlueprint ? `${item.shortname}.blueprint` : item.shortname;
              acc[key] = { Min: item.min, Max: item.max };
              return acc;
            }, {}),
          ItemsMax: container.itemsMax,
          ItemsMin: container.itemsMin,
          MaxBPs: container.maxBPs,
          Scrap: container.scrap,
        };
      });
      return { LootTables: lootTables };
    },
    loadDefaultConfig() {
      fetch("/default_config.json")
        .then((response) => response.json())
        .then((defaultConfig) => {
          this.containerItems = [];
          Object.keys(defaultConfig.LootTables).forEach((location) => {
            const container = this.containers.find((c) => c.location === location);
            if (container) {
              const items = defaultConfig.LootTables[location].ItemList;
              for (const [shortname, { Min, Max }] of Object.entries(items)) {
                const item = this.items.find((i) => i.shortname === shortname.replace(".blueprint", ""));
                if (item) {
                  const isBlueprint = shortname.endsWith(".blueprint");
                  this.containerItems.push({
                    ...item,
                    isBlueprint,
                    min: Min,
                    max: Max,
                    containerId: container.id,
                  });
                }
              }
            }
          });
        });
    },
  },
};
</script>

<style>
#app {
  line-height: 1.6;
  color: #ffffff;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
}

/* Define the styles for the scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #333; /* Dark background color */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555; /* Dark color for the scrollbar thumb */
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777; /* Lighter color when hovered */
}
</style>
