<template>
  <div>
    <h3 class="text-white text-center">ITEMS</h3>
    <input type="text" class="form-control mb-3" v-model="searchQuery" placeholder="Search items...">
    <div class="item-list-container">
      <div v-for="item in filteredItems" :key="item.id + (item.isBlueprint ? '-bp' : '')" class="col-12 col-md-6 col-lg-3 mb-4 mb-1 item-card">
        <div 
          class="card bg-dark" 
          @click="toggleItem(item)" 
          @mouseover="hoveredItem = item.id" 
          @mouseleave="hoveredItem = null"
          :class="{ 'disabled-item': isItemAdded(item) }"
        >
          <img :src="item.image" class="card-img-top" :alt="item.name">
          <div v-if="item.hasBlueprint && isItemAdded({ ...item, isBlueprint: true })" class="blueprint-indicator indicator-dot">
            <img src="https://files.facepunch.com/rust/item/blueprintbase_64.png" class="w-100">
          </div>
          <div v-if="hoveredItem === item.id" class="hover-overlay">
            <span>{{ item.name }}</span>
          </div>
          <div v-if="isItemAdded(item)" class="indicator-dot"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items', 'containerItems', 'selectedContainer'],
  data() {
    return {
      searchQuery: '',
      hoveredItem: null
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    }
  },
  methods: {
    toggleItem(item) {
      if (this.isItemAdded(item)) {
        const blueprintItem = { ...item, isBlueprint: true };
        if (this.isItemAdded(blueprintItem)) {
          this.$emit('remove-item', item);
          this.$emit('remove-item', blueprintItem);
        } else {
          this.$emit('add-item', blueprintItem);
        }
      } else {
        this.$emit('add-item', item);
      }
    },
    isItemAdded(item) {
      if (!this.selectedContainer) {
        return false;
      }
      return this.containerItems.some(ci => ci.shortname === item.shortname && ci.isBlueprint === item.isBlueprint && ci.containerId === this.selectedContainer.id);
    }
  }
}
</script>

<style scoped>
.card {
  cursor: pointer;
  position: relative;
}
.card img {
  width: 100%;
  height: auto;
}
.blueprint-indicator {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
}
.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: center;
  font-size: small;
}
.card:hover .hover-overlay {
  opacity: 1;
}
.item-list-container {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
}
.item-card {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 5px;
}
.indicator-dot {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 0%;
}
.disabled-item {
  pointer-events: none;
  opacity: 0.6;
}
</style>
